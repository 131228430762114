import { map } from 'lodash-es';
import { postData } from '../redux/fetchService/baseFetch';
import { FormFields, RebatesAPIPayload, type EquipmentRecommendationAPIPayload } from '../typedefs';
import { createApiThunk } from './utils/ApiThunkCreator';

const setNumberIfPresent = (value: string) => (value !== '' && value !== undefined ? Number(value) : undefined);

export class RebatesAPI {
  fetchRebates = async (params: {
    formData: Pick<FormFields, 'zipCode' | 'furnaceType' | 'layoutType' | 'ductedDelivery' | 'selectedHudAmi'>;
    recommendations: EquipmentRecommendationAPIPayload[];
  }): Promise<RebatesAPIPayload> => {
    const { formData, recommendations } = params;
    const { zipCode, furnaceType, layoutType, ductedDelivery, selectedHudAmi } = formData;
    return postData('rebates', {
      zipCode: setNumberIfPresent(zipCode),
      furnaceType,
      layoutType,
      ductedDelivery,
      recommendationIds: map(recommendations, (rec) => rec.recommendationId),
      selectedHudAmi,
    });
  };
}

export const fetchRebatesApiThunk = createApiThunk('rebatesApi/get', new RebatesAPI().fetchRebates);
