import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { filter, flatMap, mapValues, some, uniq, values } from 'lodash-es';
import type { RootState } from '.';
import { fetchRebatesApiThunk } from '../../api/RebatesAPI';
import type { EquipmentRecommendationAPIPayload, RebateState } from '../../typedefs';
import { EMPTY_LIST } from '../../utils/constants';
import type { RebateIncomeQualificationCriteria } from '../../utils/enums';

const initialState: RebateState = {
  allowIncomeQualificationCriteria: false,
  rebates: {},
};

const slice = createSlice({
  name: 'rebates',
  initialState,
  reducers: {
    setAllowIncomeQualificationCriteria(state, action: PayloadAction<boolean>) {
      if (state.allowIncomeQualificationCriteria !== action.payload) {
        return { ...state, allowIncomeQualificationCriteria: action.payload };
      }

      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchRebatesApiThunk.fulfilled, (state, action) => {
      return {
        ...state,
        rebates: action.payload,
      };
    });
  },
});

export const rebateActions = slice.actions;

const getRebatesPayload = (store: RootState) => store.rebates.rebates;
export const getAllowIncomeQualificationCriteria = (store: RootState) => store.rebates.allowIncomeQualificationCriteria;

export const getUnfilteredRebatesByRecommendationId = createSelector([getRebatesPayload], (rebates) => {
  const rebatesByRecommendationId = mapValues(rebates, (rebatesAndEligibility) => {
    return rebatesAndEligibility.rebates;
  });
  return rebatesByRecommendationId;
});

export const hasIncomeQualifiedRebatesByRecommendationId = createSelector(getRebatesPayload, (rebates) => {
  return mapValues(rebates, (rebatesAndEligibility) => {
    return rebatesAndEligibility.eligibleForIncomeQualifiedRebates;
  });
});

export const hasIncomeQualifiedRebatesForProject = (store: RootState, project: EquipmentRecommendationAPIPayload) => {
  const hasIncomeQualifiedRebatesFlagByRecommendationId = hasIncomeQualifiedRebatesByRecommendationId(store);
  return hasIncomeQualifiedRebatesFlagByRecommendationId[project.recommendationId] || false;
};

export const hasAnyIncomeBracketedRebatesInRecommendations = createSelector(
  [getRebatesPayload, getAllowIncomeQualificationCriteria],
  (rebates, allowIncomeQualificationCriteria) => {
    if (!allowIncomeQualificationCriteria) return false;

    const eligibleForIncomeBracketedRebates = some(
      values(rebates),
      (recommendationRebateAndElibiglity) => recommendationRebateAndElibiglity.eligibleForIncomeBracketedRebates,
    );
    return eligibleForIncomeBracketedRebates;
  },
);

export const getAllIncomeQualificationCriteria = createSelector(
  [getRebatesPayload, getAllowIncomeQualificationCriteria],
  (rebates, allowIncomeQualificationCriteria) => {
    if (!allowIncomeQualificationCriteria) return EMPTY_LIST;

    const iqCriteria = flatMap(rebates, (rebatesAndEligibility) => {
      return rebatesAndEligibility.possibleIncomeQualificationCriteria;
    });
    return uniq(filter(iqCriteria)) as RebateIncomeQualificationCriteria[];
  },
);

export default slice.reducer;
